module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"hoverkraft.cloud"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://hoverkraft.cloud","localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"en","i18nextOptions":{"keySeparator":false,"nsSeparator":"::"},"pages":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hoverkraft","short_name":"Hoverkraft","start_url":"/","background_color":"#1d2026","theme_color":"#1998ff","display":"minimal-ui","icon":"/home/runner/work/landing-page/landing-page/src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92a63f332c097d6195b14eea31568a87"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
